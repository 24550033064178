/* @flow */

import type { ProductCardProduct, Product, GAProduct } from "shop-state/types";

import React, { useState, useEffect, useRef, useContext } from "react";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import cn from "classnames";
import { pointsPriceByID } from "helpers/points";
import { productIsNotLackingPrice } from "helpers/utils";
import useFormat from "helpers/use-format";
import PriceSplit from "components/PriceSplit";
import Pixel from "components/Pixel";

import styles from "./styles.scss";

type ProductCardProps = {
  product: ProductCardProduct | Product,
  list?: string,
  position?: number,
  className?: string,
};

type DummyCardProps = {
  className?: string,
};

const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

const ProductCard = ({ product, list, position, className }: ProductCardProps): React$Node => {
  const imageRef = useRef();
  const gaRef: { current: null | Element } = useRef(null);
  const gaContext = useContext(AnalyticsContext);
  // Default to smallest image
  const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
  const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
  const image = useCurrentSrc(imageRef, defaultImage.x1);
  const pointsPrice = pointsPriceByID(product.pointsPrices, "awardit");
  const { formatPoints } = useFormat();
  const { campaignOriginalPrice } = product.attributes;

  const campaignDefaultPrice =
    campaignOriginalPrice !== null && campaignOriginalPrice !== undefined ?
      Number.parseInt(campaignOriginalPrice, 10) : 0;

  const hasReducedPrice = Boolean(campaignDefaultPrice !== 0 &&
    pointsPrice && pointsPrice.points.value.exVat < campaignDefaultPrice);

  const mapGaObject = (product: ProductCardProduct | Product): GAProduct => {
    const gaObject: GAProduct = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };
    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      list={list}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image,
          },
          list,
          position: Number(position) + 1,
        },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
      position={Number(position) + 1}
    >
      <picture className={styles.imageWrapper}>
        {largeImage &&
          <source
            srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
            media={`(min-width: ${styles.large}px)`}
          />
        }
        {mediumImage &&
          <source
            srcSet={`${mediumImage.x1} 1x, ${mediumImage.x2} 2x`}
            media={`(min-width: ${styles.medium}px)`}
          />
        }
        {smallImage &&
          <source
            srcSet={`${smallImage.x1} 1x, ${smallImage.x2} 2x`}
            media={`(min-width: ${styles.small}px)`}
          />
        }
        {tinyImage &&
          <source
            srcSet={`${tinyImage.x1} 1x, ${tinyImage.x2} 2x`}
            media="(min-width: 0px)"
          />
        }

        <img
          ref={imageRef}
          alt={product.name}
          src={defaultImage.x1}
          className={styles.image}
        />
        <Pixel className={styles.imagePixel} />
      </picture>

      <div className={cn("awardit-productCardBody", styles.body)}>
        <span className={styles.name}>
          {product.name}
        </span>
        <p className={styles.brand}>
          {product.attributes.manufacturer}
        </p>
        <div className={styles.priceInfo}>
          {productIsNotLackingPrice(product) && hasReducedPrice && (
            <span className={cn(styles.price, styles.prevPrice)}>
              {formatPoints(campaignDefaultPrice)}
            </span>
          )}
          {productIsNotLackingPrice(product) && (
            <PriceSplit
              className={cn("awardit-productCardPriceSplit", styles.price, { [styles.reducedPrice]: hasReducedPrice })}
              pointsPrice={pointsPrice} price={product.price}
            />
          )}
        </div>
      </div>
    </ProductLink>
  );
};

export const DummyCard = ({ className }: DummyCardProps): React$Node => {
  return (
    <div className={cn(styles.block, styles.dummy, className)}>
      <Pixel className={styles.image} />

      <div className={styles.body}>
        <p className={styles.brand}>&nbsp;</p>

        <span className={styles.name}>&nbsp;</span>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
