/* @flow */

import type { ConnectedPartner } from "shop-state/types";

import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { HomeData, AffiliateListSelectedData, CustomerData, CurrentPageInfoData, ViewModeData } from "data";
import ProductCard, { DummyCard } from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import { getCurrentPageInfo } from "state/current-page-info";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import UploadImageSection from "components/HomeView/UploadImageSection";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import HomeHero from "components/HomeHero";
import { Title, Items, Item } from "components/UiComponents";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import { getCustomerData, getNumberBasedOnBrowserWidth, tryParseJSONObject } from "helpers/utils";
import Agreement from "components/Agreement";
import companyData from "../CompanyList/companyData.json";
import { addMessage } from "state/messages";
import { logout } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree, connectedPartners as connectedPartnersQuery } from "queries";
import { MODE } from "state/view-mode";
import NewsCarousel from "./NewsCarousel";

import cn from "classnames";
import styles from "./styles.scss";

const HomeView = (): React$Node => {
  const {
    routes,
    content: {
      homeview: content,
      allproductsview: { popularCategories, popularCategoriesTitle },
      productCarousel: { useOnHomeView },
    },
    configuration,
  } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const affiliateList = useData(AffiliateListSelectedData);
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const [showTerms, setShowTerms] = useState(false);
  const [connectedPartners, setConnectedPartners] = useState<Array<ConnectedPartner>>([]);
  const isBrowser = useBrowser();
  const client = useClient();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;
  const numAffiliates = getNumberBasedOnBrowserWidth(
    styles,
    { small: 4, medium: 6 },
    8,
    browserWidth
  );
  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  const getConnectedPartners = async () => {
    await client(connectedPartnersQuery).then(({ connectedPartners }) => {
      if (connectedPartners !== null && connectedPartners !== undefined) {
        setConnectedPartners(connectedPartners);
      }
    });
  };

  useEffect(() => {
    getConnectedPartners();

    if (!customer?.addresses[0].telephone) {
      sendMessage(addMessage("NUMBER_NOT_FOUND", "account-details-missing"));
    }
  }, []);

  useEffect(() => {
    if (connectedPartners.length > 0) {
      const primaryPartner = connectedPartners.find(partner => partner.primary === true);

      sendMessage(getCurrentPageInfo("HOMEVIEW", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO1", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO2", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO3", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO4", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO5", primaryPartner?.name));
      sendMessage(getCurrentPageInfo("CURRENTINFO6", primaryPartner?.name));
    }
  }, [connectedPartners]);

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer && customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    await client(awarditAgreementAgree);
  };

  const earnOnlineOrder = content.earnOnlineOrder ?
    Number.parseInt(content.earnOnlineOrder, 10) : -1;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) : -1;
  const additionalHtmlOrder = content.additionalHtmlOrder ?
    Number.parseInt(content.additionalHtmlOrder, 10) : -1;
  const additionalHtmlSecondaryOrder = content.additionalHtmlSecondaryOrder ?
    Number.parseInt(content.additionalHtmlSecondaryOrder, 10) : -1;

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) : 1;
  const currentInfo2Order = content.currentInfo2Order ?
    Number.parseInt(content.currentInfo2Order, 10) : -1;
  const currentInfo3Order = content.currentInfo3Order ?
    Number.parseInt(content.currentInfo3Order, 10) : -1;
  const currentInfo4Order = content.currentInfo4Order ?
    Number.parseInt(content.currentInfo4Order, 10) : -1;
  const currentInfo5Order = content.currentInfo5Order ?
    Number.parseInt(content.currentInfo5Order, 10) : -1;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) : -1;

  const uploadImageOrder = content.uploadImageOrder ?
    Number.parseInt(content.uploadImageOrder, 10) : -1;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) : -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) : -1;

  const getItemAmount = (config, defaultAmount) => {
    if (browserWidth > 0) {
      const currentBreakpoint = Object.keys(config).find(breakpoint => {
        return browserWidth <= parseInt(styles[breakpoint], 10);
      });

      return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
    }

    return 0;
  };

  const getCurrentInfoArray = (currentInfoCount, currentPageInfoData, page) => {
    if (currentPageInfoData.state === "LOADED" &&
        currentPageInfoData.data[`currentinfo${page}`] &&
        currentPageInfoData.data[`currentinfo${page}`].length > 0) {
      return currentInfoCount > 0 ?
        currentPageInfoData.data[`currentinfo${page}`].slice(0, currentInfoCount) :
        currentPageInfoData.data[`currentinfo${page}`];
    }

    return [];
  };

  const currentInfo1Count = content.currentInfo1Count ?
    Number.parseInt(content.currentInfo1Count, 10) : 0;
  const currentInfo2Count = content.currentInfo2Count ?
    Number.parseInt(content.currentInfo2Count, 10) : 0;
  const currentInfo3Count = content.currentInfo3Count ?
    Number.parseInt(content.currentInfo3Count, 10) : 0;
  const currentInfo4Count = content.currentInfo4Count ?
    Number.parseInt(content.currentInfo4Count, 10) : 0;
  const currentInfo5Count = content.currentInfo5Count ?
    Number.parseInt(content.currentInfo5Count, 10) : 0;
  const currentInfo6Count = content.currentInfo6Count ?
    Number.parseInt(content.currentInfo6Count, 10) : 0;
  const currentInfo1Array = getCurrentInfoArray(currentInfo1Count, currentPageInfoData, 1);
  const currentInfo2Array = getCurrentInfoArray(currentInfo2Count, currentPageInfoData, 2);
  const currentInfo3Array = getCurrentInfoArray(currentInfo3Count, currentPageInfoData, 3);
  const currentInfo4Array = getCurrentInfoArray(currentInfo4Count, currentPageInfoData, 4);
  const currentInfo5Array = getCurrentInfoArray(currentInfo5Count, currentPageInfoData, 5);
  const currentInfo6Array = getCurrentInfoArray(currentInfo6Count, currentPageInfoData, 6);

  const featuredProductsAmount = getItemAmount({ small: 2, medium: 3 }, 4);

  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";

  const featuredProducts = useMemo(() => home.state !== "LOADED" ? [...new Array(4)] :
    home.data.featuredProducts && home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId)
      .sort(() => 0.5 - Math.random())
      .slice(0, featuredProductsAmount
      ), [home.state, featuredProductsAmount]);

  const affiliateItems = (affiliateList.state === "LOADED" ? affiliateList.data : []).slice(0, numAffiliates);

  const earnOnlineSection = (
    <div key="earnOnline" className={styles.section}>
      <div className={styles.top}>
        {content.earnOnlineHeading &&
          <Title className={styles.flexHeading} elem="h2">{content.earnOnlineHeading}</Title>
        }
        {content.earnOnlineButtonLink && content.earnOnlineButtonText &&
        <div className={styles.earnOnline}>
          <Button
            to={content.earnOnlineButtonLink}
            className={cn("link", styles.cta)}
          >
            {content.earnOnlineButtonText ?? ""}
          </Button>
        </div>
        }
      </div>
      {affiliateList.state === "LOADED" &&
        <AffiliateList items={affiliateItems} />
      }

      {affiliateList.state === "LOADING" &&
        <AffiliateDummyList items={Array.from({
          length: numAffiliates,
        }, () => null)} />
      }
    </div>
  );

  const featuredProductsSection = (
    <div key="featuredProducts" className={styles.section}>
      <div className={styles.top}>
        {content.featuredProductsHeading &&
          <Title className={styles.flexHeading} elem="h2">{content.featuredProductsHeading}</Title>
        }
        {content.featuredProductsButtonLink && content.featuredProductsButtonText &&
          <div className={styles.featuredProducts}>
            <Button
              to={content.featuredProductsButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.featuredProductsButtonText ?? ""}
            </Button>
          </div>
        }
      </div>
      <Items className={styles.featuredProductsItems}>
        {featuredProducts && featuredProducts.map((p, i) =>
          p ?
            <Item key={p.name + i} className={styles.featuredProductsItem}>
              <ProductCard product={p} list={content.featuredProductsHeading ?? ""} position={i} />
            </Item> :
            <Item key={i}><DummyCard /></Item>
        )}
      </Items>
    </div>
  );

  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const currentInfo1Section = (
    (currentInfo1Array && currentInfo1Array.length > 0 &&
      routes.currentInfoView1 && routes.currentInfoView1.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo1Array} currInfoNum={1} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );
  const currentInfo2Section = (
    (currentInfo2Array && currentInfo2Array.length > 0 &&
      routes.currentInfoView2 && routes.currentInfoView2.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo2Array} currInfoNum={2} adaptiveHeightLimit={375} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );
  const currentInfo3Section = (
    (currentInfo3Array && currentInfo3Array.length > 0 &&
      routes.currentInfoView3 && routes.currentInfoView3.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo3Array} currInfoNum={3} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );
  const currentInfo4Section = (
    (currentInfo4Array && currentInfo4Array.length > 0 &&
      routes.currentInfoView4 && routes.currentInfoView4.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo4Array} currInfoNum={4} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );
  const currentInfo5Section = (
    (currentInfo5Array && currentInfo5Array.length > 0 &&
      routes.currentInfoView5 && routes.currentInfoView5.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo5Array} currInfoNum={5} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );
  const currentInfo6Section = (
    (currentInfo6Array && currentInfo6Array.length > 0 &&
      routes.currentInfoView6 && routes.currentInfoView6.url) && (
      <div className="awardit-currenInfoCarouselWrapper">
        <NewsCarousel infoArray={currentInfo6Array} currInfoNum={6} loading={currentPageInfoData.state === "LOADING"} />
      </div>
    )
  );

  const popularProductsSection = (
    Boolean(useOnHomeView) && (
      <Wrapper className={styles.popularProducts}>
        <ProductCarousel />
      </Wrapper>
    )
  );

  const popularCategoriesSection = (
    <Wrapper className={styles.popularCategories}>
      {popularCategoriesJson &&
        <PopularCategoryCarousel
          title={popularCategoriesTitle ?? ""}
          popularCategories={popularCategoriesJson}
        />
      }
    </Wrapper>
  );

  const mapPartnerLinks = () => {
    return connectedPartners.map((partner, index) => {
      const company = companyData.sgds.find(item => {
        return item.name.toLowerCase() === partner.name.toLowerCase();
      });

      if (company === undefined) {
        return null;
      }

      return (
        <Button key={`${index}-${partner.name}`} to="/companies" className={styles.cardListItem}>
          <div className={cn(styles.customerTag, { [styles.isCustomer]: partner.connected })}>
            <p>{partner.connected ? "Kund" : "Bli kund"}</p>
          </div>
          <img
            className={styles.cardListImage}
            alt={partner.name}
            src={company.image}
          />
        </Button>
      );
    });
  };

  const main = [];

  main[earnOnlineOrder] = earnOnlineSection;
  main[featuredProductsOrder] = featuredProductsSection;

  if (additionalHtmlOrder >= 0) {
    main[additionalHtmlOrder] = additionalHtmlSection;
  }

  if (additionalHtmlSecondaryOrder >= 0) {
    main[additionalHtmlSecondaryOrder] = additionalHtmlSecondarySection;
  }

  if (currentInfoOrder >= 0) {
    main[currentInfoOrder] = currentInfo1Section;
  }

  if (currentInfo2Order >= 0) {
    main[currentInfo2Order] = currentInfo2Section;
  }

  if (currentInfo3Order >= 0) {
    main[currentInfo3Order] = currentInfo3Section;
  }

  if (currentInfo4Order >= 0) {
    main[currentInfo4Order] = currentInfo4Section;
  }

  if (currentInfo5Order >= 0) {
    main[currentInfo5Order] = currentInfo5Section;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  if (uploadImageOrder >= 0) {
    main[uploadImageOrder] = <UploadImageSection />;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  return (
    <div className={cn(styles.block, "awardit-homeView")}>
      <Helmet
        title={content.pageTitle ?? ""}
      />
      <HomeHero partners={connectedPartners} />
      <Wrapper className={cn(styles.wrapper, "awardit-homeViewWrapper")}>
        <div className={styles.companyDataIntro}>
          <Title className={styles.flexHeading} elem="h2">Hos de här bolagen kan du samla poäng i Proffsklubben</Title>
          <p>
            <span>Medlemskriterier för respektive bolag kan du läsa <Link to="/companies">här »</Link></span><br />
            <span>Bli kund hos våra systerbolag, klicka på logon nedan.</span>
          </p>
          <div className={styles.cardList}>
            {mapPartnerLinks()}
          </div>
        </div>
        {main}
      </Wrapper>

      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
              setShowTerms(open);
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
