/* @flow */

import type { Transaction } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Button } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import { timestampToDateString } from "helpers/locale-utils";
import Points from "components/AccountView/TransactionsView/Points";
import Spinner from "components/Spinner";
import ClipIcon from "icons/clip.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type TableLargeProps = {
  items: Array<Transaction>,
  setCurrentReceipt: string => void,
};

const TableLargeHead = () => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  return (
    <thead>
      <tr>
        <th>{transactionsview.tableId}</th>
        <th>{transactionsview.tableTransaction}</th>
        {Boolean(!transactionsview.hideTransactionSource) && <th>{transactionsview.tableClub}</th>}
        <th>{transactionsview.tablePoints}</th>
        <th>{transactionsview.tableDate}</th>
        <th>{transactionsview.tablePointsExpires}</th>
        <th>{transactionsview.tableReceipt}</th>
      </tr>
    </thead>
  );
};

const TableLarge = ({ items, setCurrentReceipt }: TableLargeProps): React$Node => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);
  const t = useTranslate();

  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead />
        <tbody>
          {items.map((x, i) => (
            <tr key={`${x.id}-${i}`}>
              <td>{x.id}</td>
              <td>{x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description}</td>
              {Boolean(!transactionsview.hideTransactionSource) && <td>{x.club}</td>}
              <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                <Points points={x.points} />
              </td>
              <td>{timestampToDateString(x.createdAt)}</td>
              <td>{x.expiresAt !== null && timestampToDateString(x.expiresAt)}</td>
              <td>
                {x.flag === "DOWN" && x.type !== 468 && x.type !== 470 && (
                  <Button
                    className={styles.receiptLink}
                    onClick={() => setCurrentReceipt(x.id)}
                  >
                    <ClipIcon />
                    <span>{transactionsview.receipt}</span>
                  </Button>
                )}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableLargeHint = (): React$Node => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
