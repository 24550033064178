/* @flow */

import React from "react";
import Button from "components/Button";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  text: string,
  className?: string,
  size?: string,
};

const AdminButton = ({ text, className, size }: Props): React$Node => {
  return (
    <a
      className={cn(styles.adminButton, className)}
      href="/partner/MemberLogin.jsp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        className={styles.button}
        variant="primaryAlt"
        size={size}
      >
        {text}
      </Button>
    </a>
  );
};

export default AdminButton;
