/* @flow */

import type { Product } from "shop-state/types";

import styles from "./styles.scss";
import cn from "classnames";
import useBrowserDimensions from "helpers/use-browser-dimensions";

import React, { useEffect, useState, useContext } from "react";
import { useData } from "crustate/react";
import { CustomerData, QuoteData } from "data";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { getNumberBasedOnBrowserWidth, getCustomerData } from "helpers/utils";

import ProductCard, { DummyCard } from "components/ProductCard";
import Carousel from "components/Carousel";
import { Title, Item } from "components/UiComponents";
import { inStock } from "@crossroads/ui-components";
import { pointsPriceByID } from "helpers/points";
import { useGetRecProductsSkus, useGetRecProductByBalance, useGetRecProductsCategory } from "helpers/get-products";

import { productBySku } from "queries";

type Variants = {
  variant?: "TOPLIST" | "POINTSBALANCE" | "CATEGORY" | "PRODUCT",
};
type Props = {
  className?: string,
  category?: string,
  sku?: string,
} & Variants;

type ProductsTypes = Array<{ product: Product }>;

const ProductCarouselTitle = ({ variant }: Variants) => {
  const {
    content: {
      productCarousel: {
        allProductsViewTitle,
        productViewTitle,
        categoryViewTitle,
        cartTitle },
    },
  } = useContext(StoreInfoContext);

  switch (variant) {
    case "TOPLIST":
      return allProductsViewTitle ? <Title elem="h2">{allProductsViewTitle}</Title> : null;
    case "POINTSBALANCE":
      return cartTitle ? <Title elem="h2">{cartTitle}</Title> : null;
    case "CATEGORY":
      return categoryViewTitle ? <Title elem="h2">{categoryViewTitle}</Title> : null;
    case "PRODUCT":
      return productViewTitle ? <Title elem="h2">{productViewTitle}</Title> : null;
    default:
      return allProductsViewTitle ? <Title elem="h2">{allProductsViewTitle}</Title> : null;
  }
};

const ProductCarousel = ({ className, variant = "TOPLIST", category, sku }: Props): React$Node => {
  const client = useClient();
  const customer = getCustomerData(useData(CustomerData));
  const quoteState = useData(QuoteData);
  const anonymizedUserId = customer?.awarditAnonymizedId ?? "USER";
  const { width: browserWidth } = useBrowserDimensions();
  const {
    content: {
      productCarousel: {
        desktopItemAmount,
      },
    },
  } = useContext(StoreInfoContext);
  const desktopItemAmountNumber = desktopItemAmount ? parseInt(desktopItemAmount, 10) : 0;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductsTypes>([]);
  const [skus, setSkus] = useState<Array<string>>([]);

  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  const fetchSkus = async () => {
    switch (variant) {
      case "POINTSBALANCE":

        const quote = quoteState.data || null;

        if (!quote || !customer) {
          setIsLoading(false);
          return null;
        }

        // Compare activePoints with the minimum points of products in quote
        const pointPayment = await pointsPriceByID(quote.availablePointPayments, "awardit");
        const minPointsToPay = pointPayment ? pointPayment.points.min.exVat : 0;
        const customerPointBalance = customer.awardit.activePoints;
        const availablePoints = (minPointsToPay !== undefined &&
            customerPointBalance > minPointsToPay) ?
          parseInt(customerPointBalance - minPointsToPay, 10) : 0;
        if (availablePoints && availablePoints > 0) {
          const availablePointString = availablePoints.toString();
          try {
            /* eslint-disable react-hooks/rules-of-hooks */
            const pointsBalanceResponse =
            await useGetRecProductByBalance(anonymizedUserId,
              partnerApiKey ?? "",
              24,
              availablePointString);
              /* eslint-enable react-hooks/rules-of-hooks */
            if (!pointsBalanceResponse ||
              (pointsBalanceResponse && pointsBalanceResponse.length === 0)) {
              setIsLoading(false);
              return null;
            }

            setSkus(pointsBalanceResponse.map(item => item.sku));
          }
          catch (e) {
            setIsLoading(false);
            console.log(e);
          }
        }

        setIsLoading(false);
        return null;
      case "CATEGORY":
        if (!category) {
          setIsLoading(false);
          return null;
        }

        try {
          /* eslint-disable react-hooks/rules-of-hooks */
          const categoryResponse =
              await useGetRecProductsCategory(partnerApiKey ?? "", 24, category, sku);
            /* eslint-enable react-hooks/rules-of-hooks */

          if (!categoryResponse || !categoryResponse.length > 0) {
            setIsLoading(false);
            return null;
          }

          setSkus(categoryResponse.map(item => item.sku));
        }
        catch (e) {
          setIsLoading(false);
          console.log(e);
        }

        return null;
      case "PRODUCT":
        if (!category) {
          setIsLoading(false);
          return null;
        }

        try {
          /* eslint-disable react-hooks/rules-of-hooks */
          const categoryResponse =
              await useGetRecProductsCategory(partnerApiKey ?? "", 24, category, sku);
            /* eslint-enable react-hooks/rules-of-hooks */

          if (!categoryResponse || !categoryResponse.length > 0) {
            setIsLoading(false);
            return null;
          }

          setSkus(categoryResponse.map(item => item.sku));
        }
        catch (e) {
          setIsLoading(false);
          console.log(e);
        }

        return null;
      default:
        try {
          /* eslint-disable react-hooks/rules-of-hooks */
          const topListResponse = await useGetRecProductsSkus(anonymizedUserId, partnerApiKey ?? "", 24);
          /* eslint-enable react-hooks/rules-of-hooks */

          if (!topListResponse || !topListResponse.length > 0) {
            setIsLoading(false);
            return null;
          }

          setSkus(topListResponse.map(item => item.sku));
        }
        catch (e) {
          setIsLoading(false);
          console.log(e);
        }

        return null;
    }
  };

  useEffect(() => {
    fetchSkus();
  }, []);

  useEffect(() => {
    if (skus.length > 0) {
      fetchProductsFromSkus();
    }
  }, [skus]);

  const {
    content: {
      productCarousel: { partnerApiKey },
    },
  } = useContext(StoreInfoContext);

  const numItems = getNumberBasedOnBrowserWidth(
    styles,
    { small: 2, medium: 4, large: desktopItemAmountNumber },
    desktopItemAmountNumber,
    browserWidth
  );

  const fetchProductsFromSkus = async () => {
    // tillfällig try/catch istället för att använda crustate
    try {
      const productsData = await Promise.all(
        skus.map(sku => client(productBySku, { sku }))
      ).then(results => results.map(result => result));

      // apply target group filtering
      const existingProducts = productsData.filter(item => item.product !== null && item.product.type !== "configurable");
      const inStockProducts = existingProducts.filter(item => inStock(item.product, {}));
      const filteredProducts = inStockProducts.filter(item => !memberTargetList ||
        memberTargetList.includes(item.product.attributes.awarditTargetId) ||
        !item.product.attributes.awarditTargetId);

      setProducts(filteredProducts);
      setIsLoading(false);
    }
    catch (e) {
      console.error(e);
    }
  };

  return (
    isLoading ? <ProductCarouselDummy /> : (
      (products && products.length >= desktopItemAmountNumber) && (
        <div className={cn(styles.productCarousel, [`displays-${desktopItemAmountNumber}`], className)}>
          <ProductCarouselTitle variant={variant} />
          <div className={cn(styles.productCarouselList, "awardit-productCarouselList")}>
            <Carousel
              autoplay
              className={styles.carousel}
              items={products.map(item => (
                <Item key={item.product.name} className={styles.productCarouselItem}>
                  <ProductCard
                    product={item.product}
                    list={`recom_products_${variant.toLowerCase()}`}
                    className={cn(styles.productCard, "awardit-productCarouselCard")}
                  />
                </Item>
              ))}
              slidesToScroll={numItems}
              slidesToShow={numItems}
              timer={7500}
            />
          </div>
        </div>
      )
    )
  );
};

export const ProductCarouselDummy = (): React$Node => {
  const { width: browserWidth } = useBrowserDimensions();
  const {
    content: {
      productCarousel: {
        desktopItemAmount,
      },
    },
  } = useContext(StoreInfoContext);
  const desktopItemAmountNumber = parseInt(desktopItemAmount, 10);
  const numItems = getNumberBasedOnBrowserWidth(
    styles,
    { small: 2, medium: 4, large: desktopItemAmountNumber },
    desktopItemAmountNumber,
    browserWidth
  );

  return (
    <div className={cn(styles.productCarousel, styles[`displays-${desktopItemAmountNumber}`], styles.dummy)}>
      <Title className={styles.title} elem="h2">&nbsp;</Title>
      <div className={styles.productCarouselList}>
        <Carousel
          className={styles.carousel}
          items={Array.from({ length: desktopItemAmountNumber }).map((_, i) => (
            <Item key={i} className={styles.productCarouselItem}>
              <DummyCard className={styles.productCard} />
            </Item>
          ))}
          slidesToScroll={numItems}
          slidesToShow={numItems}
          timer={7500}
        />
      </div>
    </div>
  );
};

export default ProductCarousel;
