/* @flow */

import type { Routes } from "shop-state/types";

import React from "react";
import { Route } from "react-router";
import { TinkTransactionsData } from "data";
import TinkView from "components/AccountView/TinkView";
import getCurrentInfoRoutes from "components/CurrentInfo/routes";

// Theme specific components
import About from "../About";
import CompanyList from "../CompanyList";
import CompanyContact from "../CompanyList/contact";
import AccountingRecordsView from "../AccountView/AccountingRecordsView";

type Props = {
  routes: Routes,
  partnerId?: number,
};

const additionalRoutes = ({ routes }: Props): Array<
React$Element<typeof Route> | Array<React$Node>
> => {
  return [
    ...(routes.accountTinkView &&
    routes.accountTinkView.toggle !== undefined &&
    routes.accountTinkView.toggle &&
    typeof routes.accountTinkView.url === "string" &&
    routes.accountTinkView.url.length > 0 ?
      [
        <Route
          key={routes.accountTinkView.url}
          path={routes.accountTinkView.url}
          render={() => (
            <TinkTransactionsData.Provider>
              <TinkView />
            </TinkTransactionsData.Provider>
          )}
        />,
      ] : []),
    ...(routes.accountingRecordsView &&
    routes.accountingRecordsView.toggle !== undefined &&
    routes.accountingRecordsView.toggle &&
    typeof routes.accountingRecordsView.url === "string" &&
    routes.accountingRecordsView.url.length > 0 ?
      [
        <Route
          key={routes.accountingRecordsView.url}
          path={routes.accountingRecordsView.url}
          render={() => (
            <AccountingRecordsView />
          )}
        />,
      ] : []),
    <Route
      key="contact"
      path="/contact"
      render={() => (
        <CompanyContact />
      )}
    />,
    <Route
      key="companies"
      path="/companies"
      render={() => (
        <CompanyList />
      )}
    />,
    <Route
      key="about"
      path="/about"
      render={() => (
        <About />
      )}
    />,
    getCurrentInfoRoutes(routes),
  ];
};

export default additionalRoutes;
