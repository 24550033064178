/* @flow */

import type { TransactionFlag } from "shop-state/types";

import React, { useContext } from "react";

import { Input, Dropdown, DropdownItem } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import { subMonths } from "date-fns";
import SearchIcon from "icons/search.svg";
import ClearIcon from "icons/close-small.svg";

import styles from "./styles.scss";

export type DateFrom = { key: string | null, date: ?Date };
type TransactionFlagAll = TransactionFlag | "ALL";

type ControlsProps = {
  activeFilter: (TransactionFlagAll),
  setActiveFilter: (TransactionFlagAll) => void,
  searchValue: string,
  setSearchValue: string => void,
  dateFrom: ?DateFrom,
  setDateFrom: DateFrom => void,
  disabled: boolean,
};

const FILTER: {[string]: TransactionFlagAll} = {
  ALL: "ALL",
  IN: "UP",
  OUT: "DOWN",
  REFUND: "REFUND",
};

export const FILTER_ALL = FILTER.ALL;

export const DATE_FROM = {
  ALL: "ALL",
  HALF_YEAR: "HALF_YEAR",
  FULL_YEAR: "FULL_YEAR",
};

const checkTransactionFlag = (val: string): TransactionFlagAll => {
  const key = Object.keys(FILTER).find(key => FILTER[key] === val);
  if (key) {
    return FILTER[key];
  }

  return null;
};

const Controls = ({
  activeFilter,
  setActiveFilter,
  searchValue,
  setSearchValue,
  dateFrom,
  setDateFrom,
  disabled,
}: ControlsProps): React$Node => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  const filters = [
    [FILTER.OUT, transactionsview.filterOut],
    [FILTER.IN, transactionsview.filterIn],
    [FILTER.ALL, transactionsview.filterAll],
  ];

  if (transactionsview.allTransactions === true) {
    filters.splice(filters.length - 1, 0, [FILTER.REFUND, transactionsview.filterRefund]);
  }

  return (
    <div className={styles.controls}>
      <div className={styles.left}>
        <Input
          slotLeft={<SearchIcon className={styles.searchIcon} />}
          value={searchValue}
          placeholder={transactionsview.search}
          wrapperClassName={styles.searchInput}
          disabled={disabled}
          slotRight={searchValue &&
            <button
              className={styles.clear}
              aria-label="Clear search input"
              title="Clear search (esc)"
              type="button"
              onClick={() => setSearchValue("")}
            >
              <ClearIcon className={styles.clearIcon} />
            </button>}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>

      <div className={styles.right}>
        <Dropdown
          className={styles.fitlerPicker}
          value={activeFilter}
          disabled={disabled}
          onChange={value => {
            if (typeof value === "string") {
              const val = checkTransactionFlag(value);
              setActiveFilter(val);
            }
          }}
        >
          {filters.map(item => {
            if (item[0] !== null) {
              return (<DropdownItem key={item[0]} value={item[0]}>{item[1]}</DropdownItem>);
            }

            return null;
          })}
        </Dropdown>

        <Dropdown
          className={styles.datePicker}
          value={dateFrom?.key}
          disabled={disabled}
          placeholder={transactionsview.datePlaceholder}
          onChange={value => {
            const val = value !== null ? value.toString() : value;
            if (value === DATE_FROM.HALF_YEAR) {
              // Set date from half year from now
              setDateFrom({ key: val, date: subMonths(new Date(), 6) });
              return;
            }

            if (value === DATE_FROM.FULL_YEAR) {
              // Set date from half year from now
              setDateFrom({ key: val, date: subMonths(new Date(), 12) });
              return;
            }

            setDateFrom({ key: DATE_FROM.ALL, date: null });
          }}
        >
          <DropdownItem value={DATE_FROM.HALF_YEAR}>{transactionsview.dateHalfYear}</DropdownItem>
          <DropdownItem value={DATE_FROM.FULL_YEAR}>{transactionsview.dateFullYear}</DropdownItem>
          <DropdownItem value={DATE_FROM.ALL}>{transactionsview.dateAll}</DropdownItem>
        </Dropdown>
      </div>

    </div>
  );
};

export default Controls;
