/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";

import React, { useState, useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { CustomerData } from "data";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { focusInvalidField } from "helpers/utils";
import Field, { TogglePasswordField } from "components/Field";
import { useData, useSendMessage } from "crustate/react";
import { login } from "@crossroads/shop-state/customer";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const validation = rules([
  isRequired("username"),
  isRequired("password"),
]);

const LoginForm = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { routes, content: { loginview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const sendMessage = useSendMessage();
  const [state, setState] = useState<FormData>({});
  const loading = customer.state === "LOGGING_IN";

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    sendMessage(login(state.username.toString(), state.password.toString()));
  };

  const errors = validation(state);

  return (
    <div
      className={cn(styles.block, className, "awardit-loginForm")}
    >
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <Form
            method="POST"
            value={state}
            errors={errors}
            onError={focusInvalidField}
            onSubmit={submit}
            onChange={x => setState({ ...state, ...x })}
          >
            <Field type="text" name="username" placeholder={loginview.usernameText ?? ""} />

            <TogglePasswordField autoComplete="off" name="password" placeholder={loginview.userPasswordText ?? ""} />

            <Button
              id="login"
              type="submit"
              className={styles.button}
              loading={loading}
              variant="secondary"
            >
              {t("LOGIN.SUBMIT")}
            </Button>
            <noscript>
              <p className={styles.noScriptParagraph}>
                {t("NO_JAVASCRIPT.LOGIN")}
              </p>
            </noscript>

          </Form>
        </div>
        <div className={styles.bottom}>
          <p className={styles.resetPasswordDescription}>
            Har du ett konto, men har inte loggat in ännu?<br /> Klicka på nytt lösenord nedan.
          </p>
          {routes.passwordResetView && routes.passwordResetView.url &&
          loginview.forgotPasswordButtonText && (
            <div className={styles.forgot_password}>
              <Link to={routes.passwordResetView.url}>
                {loginview.forgotPasswordButtonText}
              </Link>
            </div>
          )}

          {loginview.createAccountEnabled !== undefined &&
          loginview.createAccountEnabled !== null &&
          loginview.createAccountEnabled === true &&
          routes.registerView && routes.registerView.url &&
            <div className={styles.no_account}>
              <Link to={routes.registerView.url}>{loginview.createAccountButtonText ?? t("LOGIN.CREATE_ACCOUNT")}</Link>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
