/* @flow */

import React, { useContext } from "react";
import type { Node as ReactNode } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import styles from "./styles.scss";

type HeaderProps = {
  subHeader: string,
  children?: ReactNode,
};

const Header = ({ subHeader, children }: HeaderProps): React$Element<"header"> => {
  const { content: { accountview } } = useContext(StoreInfoContext);

  return (
    <header className={styles.headings}>
      {accountview.title &&
        <h2>{accountview.title}</h2>
      }
      <h3>{subHeader}</h3>
      {children !== undefined && children !== null ? children : null}
    </header>
  );
};

export default Header;
