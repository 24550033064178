/* @flow */

import type { Customer } from "shop-state/types";
import type { Mode } from "state/view-mode";

import React, { useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { CartMini } from "@crossroads/ui-components";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import Questionnaires from "components/AppHeader/Large/SubMenu/questionnaires";
import cn from "classnames";
import HamburgerIcon from "icons/hamburger.svg";
import CloseIcon from "icons/close-large.svg";
import CartIcon from "icons/cart-alt.svg";
import ChevronIcon from "icons/chevron-small.svg";
import SystemMessages from "components/SystemMessages";
import CustomerBadge from "components/CustomerBadge";
import useFormat from "helpers/use-format";
import { getCustomerActiveBalance } from "helpers/points";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import { MODE } from "state/view-mode";
import Redeem from "components/AppHeader/Small/Redeem";
import Earn from "components/AppHeader/Small/Earn";
import Logo, { SecondaryLogo } from "components/AppHeader/Logo";
import AdminButton from "components/AccountView/AdminButton";

import styles from "./styles.scss";

type Props = {
  className: string,
  customer: ?Customer,
  mode: Mode,
  setMode: Mode => void,
  subNavOpen: boolean,
  setSubNavOpen: boolean => void,
  hamburgerOpen: boolean,
  setHamburgerOpen: boolean => void,
  lastVisited: ?string,
  isScrolled?: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  cartCount: number,
  logoWidth: string,
};

const AppHeaderSmall = ({
  className = "",
  mode,
  setMode,
  subNavOpen,
  setSubNavOpen,
  hamburgerOpen,
  setHamburgerOpen,
  lastVisited,
  customer,
  isScrolled,
  onCheckout,
  onSuccess,
  cartCount,
  logoWidth,
}: Props): React$Node => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const {
    routes: {
      accountView,
      checkoutView,
      earnView,
      earnViewSubpage,
      earnViewSubpage2,
      homeView,
      questionnaireView,
      redeemView,
    },
    configuration: { helpdeskTitle, helpdeskLink },
    content: { accountview },
  } = storeInfo;
  const { formatPoints } = useFormat();
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const toggleHamburger = () => hamburgerOpen ? closeHamburger() : openHamburger();
  const closeMenu = () => setMode(MODE.NORMAL);
  const history = useHistory();
  const { push } = useHistory();
  const showAdminButton = Boolean(accountview.showAdministration) &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;

  const nav = navRoutes(ROUTES, storeInfo);
  const questionnaireViewUrl = questionnaireView && questionnaireView.url ? questionnaireView.url : "";

  const QUESTIONNAIRE_ROUTES = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ];

  const openHamburger = () => {
    setMode(MODE.NORMAL);
    setIsOpening(true);
    setHamburgerOpen(true);

    setTimeout(() => {
    }, parseInt(styles.animationDuration, 10));
  };

  const closeHamburger = () => {
    setIsClosing(true);
    setSubNavOpen(false);

    setTimeout(() => {
      setHamburgerOpen(false);
      setIsClosing(false);
      setMode(MODE.NORMAL);
    }, parseInt(styles.animationDuration, 10));
  };

  useEffect(() => {
    if (mode === MODE.NORMAL || mode === MODE.CART) {
      setHamburgerOpen(false);
    }
  }, [mode, setHamburgerOpen]);

  const setModeAndOpenMenu = (mode: Mode) => {
    setMode(mode);
    setSubNavOpen(true);
  };

  if (!customer) {
    return null;
  }

  const customerBalance = getCustomerActiveBalance(customer);
  const formattedBalance = formatPoints(customerBalance);

  const gotoRedeemView = () => {
    if (redeemView?.url) {
      push(redeemView.url);
    }

    return null;
  };

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={cn(
          styles.container,
          styles["mode__" + mode],
          { [styles.hamburgerOpen]: hamburgerOpen },
          { [styles.isOpening]: isOpening },
          { [styles.isClosing]: isClosing },
          { [styles.subNavOpen]: subNavOpen }
        )}
        >
          <div className={cn(styles.block, className)}>
            <Wrapper className={cn(styles.wrapper)}>
              <div className={styles.left}>
                {homeView && homeView.url &&
                  <Link to={homeView.url} className={cn(styles.item, styles.logo)}>
                    <Logo className={styles.logoImage} width={logoWidth} isInverted={isScrolled} />
                  </Link>
                }
              </div>

              <div className={styles.right}>
                {onCheckout ? (
                  <div className={styles.close}>
                    <Button
                      className={styles.close}
                      onClick={() =>
                        lastVisited ? history.push(lastVisited) : gotoRedeemView()
                      }
                    >
                      {t(onSuccess ? "CART.CLOSE" : "CART.CLOSE_CART")}
                    </Button>
                  </div>
                ) : (
                  <nav className={styles.nav}>
                    {accountView && accountView.url &&
                      <div className={cn(styles.item, styles.customer_badge)}>
                        {accountView.url &&
                          <NavLink
                            to={accountView.url}
                            className={styles.customer_badge}
                          >
                            <CustomerBadge
                              customer={customer}
                            />
                          </NavLink>
                        }
                      </div>
                    }

                    <div
                      className={styles.item} onClick={() => {
                        if (cartCount === 0) {
                          return;
                        }

                        setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART);
                      }}
                    >
                      <CartIcon className={styles.icon} />
                      <span className={styles.cartCount}>{cartCount}</span>
                    </div>

                    <span
                      className={styles.item}
                      onClick={toggleHamburger}
                    >
                      <HamburgerIcon />
                    </span>
                  </nav>
                )}
              </div>
            </Wrapper>
          </div>
          <aside className={styles.outofcanvas}>
            <div className={styles.outofcanvasMain}>
              <Wrapper className={styles.top}>
                <SecondaryLogo width={logoWidth} />
                <Button className={styles.close} onClick={closeHamburger}>
                  <CloseIcon className={styles.closeIcon} />
                </Button>
              </Wrapper>

              {nav.map(x => (
                <React.Fragment key={x.url}>
                  {x.title === redeemView?.title ||
                  (x.title === earnView?.title &&
                  ((earnViewSubpage && earnViewSubpage.toggle) ||
                  (earnViewSubpage2 && earnViewSubpage2.toggle))) ?
                    <span
                      className={styles.item}
                      onClick={() => setModeAndOpenMenu(x.title === redeemView?.title ? "redeem" : "earn")}
                    >
                      {x.title === redeemView?.title ?
                        redeemView?.title : earnView?.title}
                      <ChevronIcon />
                    </span> :
                    <NavLink
                      className={styles.item}
                      title={x.title}
                      to={x.url ?? "/"}
                    >
                      <span>{x.title}</span>
                    </NavLink>
                  }
                </React.Fragment>
              ))}

              {helpdeskLink && helpdeskTitle &&
              <a
                href={helpdeskLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item}
                title={helpdeskTitle}
              >
                <span>{helpdeskTitle}</span>
              </a>
              }

              {customer !== null && customer !== undefined &&
            accountView && accountView.toggle !== undefined &&
            accountView.toggle && accountView.url &&
              <NavLink
                className={styles.item}
                title={accountView.title}
                to={accountView.url}
              >
                <span>{accountView.title}</span>
              </NavLink>
              }

              {cartCount > 0 && checkoutView &&
              checkoutView.url && checkoutView.title &&
              <Link
                className={styles.item}
                title={checkoutView.title}
                to={`${checkoutView.url}/shipping`}
              >
                <span>{checkoutView.title}</span>
              </Link>
              }
            </div>

            {customer !== null && customer !== undefined &&
            <Wrapper className={styles.bottom}>
              {showAdminButton &&
                <div className={cn(styles.adminBtnContainer, "awardit-adminButtonSmall")}>
                  {accountview.administration &&
                    <AdminButton text={accountview.administration} />
                  }
                </div>
              }
              <div className={styles.user}>
                <h3 className={styles.userName}>{customer.firstname} {customer.lastname}</h3>
                <p className={styles.userPoints}>{formattedBalance}</p>
              </div>
            </Wrapper>
            }
          </aside>

          {mode === MODE.REDEEM &&
          <nav className={styles.redeem}>
            <Redeem
              closeHamburger={closeHamburger}
              setHamburgerOpen={setHamburgerOpen}
              setSubNavOpen={setSubNavOpen}
              closeMenu={closeMenu}
            />
          </nav>}
          {mode === MODE.EARN &&
          <nav className={styles.earn}>
            <Earn
              closeHamburger={closeHamburger}
              setHamburgerOpen={setHamburgerOpen}
              setSubNavOpen={setSubNavOpen}
              closeMenu={closeMenu}
            />
          </nav>}
          {mode === MODE.CART && checkoutView && checkoutView.url && (
            <>
              <CartMini
                quoteData={quoteData}
                variant="b2b"
                altUrl={`${checkoutView.url}/cart`}
                ctaUrl={`${checkoutView.url}/shipping`}
                className={cn(styles.mobile, styles.miniCart)}
                onClose={() => setMode(MODE.NORMAL)}
              />
              <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
            </>
          )}
        </div>

        <Wrapper>
          <SystemMessages />
        </Wrapper>
      </div>
      <Switch>
        <Route
          path={QUESTIONNAIRE_ROUTES}
          render={() => {
            return (
              <div className={styles.subMenu}>
                <Questionnaires />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};

export default AppHeaderSmall;
