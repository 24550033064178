/* @flow */

import React from "react";

type ReceiptProps = {
  currentReceipt: string | null,
  setCurrentReceipt: (string | null) => void,
};

const NEW_WINDOW_HEIGHT = 550;
const NEW_WINDOW_WIDTH = 400;

const Receipt = ({ currentReceipt, setCurrentReceipt }: ReceiptProps): null => {
  if (currentReceipt && process.browser) {
    const receipt = currentReceipt;
    setCurrentReceipt(null);

    const left = Math.floor((screen.width - NEW_WINDOW_WIDTH) / 2);
    const top = Math.floor((screen.height - NEW_WINDOW_HEIGHT) / 2);

    window.open(
      `/TransKvitto?redseqnum=${receipt}`,
      "Print",
      `width=${NEW_WINDOW_WIDTH},height=${NEW_WINDOW_HEIGHT},menubar=no,top=scrollbars=yes,top=${top > 0 ? top : 0},left=${left > 0 ? left : 0}`
    );
  }

  return null;
};

export default Receipt;
