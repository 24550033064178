/* @flow */

import type { ConnectedPartner } from "shop-state/types";

import React, { useContext, useState, useEffect } from "react";
import cn from "classnames";
import { useClient } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import { AnalyticsContext } from "@crossroads/analytics";
import { connectedPartners as connectedPartnersQuery } from "queries";

import companyData from "./companyData.json";

import styles from "./styles.scss";

const Company = ({ connectedPartners, name, text, image, companyLinkText, companyLink }: {
  connectedPartners?: Array<ConnectedPartner>,
  name: string,
  text: string,
  image: string,
  companyLinkText?: string,
  companyLink?: string }) => {
  const connectedPartner = connectedPartners && connectedPartners.find(partner => {
    return partner.name.toLowerCase() === name.toLowerCase();
  });

  const [active, setActive] = React.useState(false);
  const gaContext = useContext(AnalyticsContext);
  const onClick = () => {
    setActive(!active);
    if (!active) {
      gaContext.registerContentClick(`Company: ${name}`, name);
    }
  };

  return (
    <div className={cn(styles.item, { [styles.active]: active })}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          alt={name}
          src={image}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.name}>
          {name}
        </p>
        {connectedPartner && connectedPartner.connected === false && (
          <a rel="noopener noreferrer" href={companyLink} target="_blank">{companyLinkText}</a>
        )}
        { connectedPartner && connectedPartner.connected === true && (
          <span>Kund</span>
        )}
        {/* eslint-disable react/no-danger */}
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className={cn(styles.text, { [styles.textHeight]: active })}
        />
        {/* eslint-enable react/no-danger */}
        <button type="button" onClick={onClick}>{active ? "Mindre info" : "Mer info"}</button>
      </div>
    </div>
  );
};

const CompanyList = (): React$Node => {
  const [connectedPartners, setConnectedPartners] = useState<Array<ConnectedPartner>>([]);
  const client = useClient();

  const getConnectedPartners = async () => {
    await client(connectedPartnersQuery).then(({ connectedPartners }) => {
      if (connectedPartners !== null && connectedPartners !== undefined) {
        setConnectedPartners(connectedPartners);
      }
    });
  };

  useEffect(() => {
    getConnectedPartners();
  }, []);

  return (
    <Wrapper className={styles.mainWrapper}>
      <Helmet
        title="Våra bolag"
      />
      <h1 className={styles.title}>Våra bolag</h1>
      <div className={styles.companies}>
        {companyData.sgds.map((item, index) => (
          <Company
            key={item.name + index}
            connectedPartners={connectedPartners}
            name={item.name}
            text={item.text}
            image={item.image}
            companyLinkText={item.companyLinkText}
            companyLink={item.companyLink}
          />
        ))}
        <Company
          connectedPartners={connectedPartners}
          name="Saint-Gobain Distribution Sweden" image="/assets/images/saint-gobain-1.png"
          text={`<p>Saint-Gobain Distribution Sweden omfattar bolagen Bevego, Dahl, Optimera, Kakelspecialisten och Konradssons Kakel och många spännande dotterbolag. Vi är nästan 3 000 branschkunniga medarbetare, som tillsammans omsätter drygt 19 miljarder och har över 180 etableringar runt om i Sverige.</p><p>Tillsammans servar vi över 150 000 kunder regelbundet, duktiga hantverkare inom bygg och installation. Det gör vi under respektive varumärke online, i butik eller direktkontakt med dess säljare.</p><p>Saint-Gobain Distribution Sweden ansvarar för att förse bolagen med koncerngemensamma funktioner och processer inom: administration, ekonomi, hållbarhet, affärsutveckling, marknadskommunikation IT och supply chain.</p><p>Vi är specialister i samverkan för bättre affärer inom bygg och installation.</p><p><a href="https://saint-gobaindistribution.se/" target="_blank">https://saint-gobaindistribution.se/ </a></p>
          </p>`} />
      </div>
    </Wrapper>
  );
};

export default CompanyList;
