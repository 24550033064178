/* @flow */

import type { PopularCategories } from "shop-state/types";

import React from "react";

import useBrowserDimensions from "helpers/use-browser-dimensions";
import { getNumberBasedOnBrowserWidth } from "helpers/utils";

import PopularCategory from "components/PopularCategory";
import { Title, Item } from "components/UiComponents";
import Carousel from "components/Carousel";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  variant?: "lightText",
  title?: string,
} & PopularCategories;

const PopularCategoryCarousel = ({
  popularCategories,
  title,
  variant = null,
}: Props): React$Node => {
  const { width: browserWidth } = useBrowserDimensions();
  const numCategories = getNumberBasedOnBrowserWidth(
    styles,
    { tiny: 2, small: 3, medium: 3, large: 6 },
    8,
    browserWidth
  );

  return (
    <div className={styles.popularCategories}>
      {title && <Title elem="h2">{title}</Title>}
      <div className={cn(styles.popularCategoriesList, "awardit-popularCategoriesList")}>
        <Carousel
          autoplay
          className={styles.carousel}
          items={popularCategories.map((item, i) => (
            <Item key={`popular-category-${i}-${item.title}`} className={styles.popularCategoriesItem}>
              <PopularCategory
                className={styles.popularCategory}
                variant={variant}
                url={item.url}
                title={item.title}
                img={item.image} />
            </Item>
          ))}
          slidesToScroll={numCategories}
          slidesToShow={numCategories}
          timer={7500}
        />
      </div>
    </div>
  );
};

export default PopularCategoryCarousel;
