/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
  height?: string,
  width: string,
  loginViewLogo?: string,
};

export const logoSvg = (logo?: string, width: string, height: string): null | React$Element<"img"> => {
  if (!logo) {
    return null;
  }

  return (
    <img src={logo} width={width} height={height} />
  );
};

export const SecondaryLogo = ({ width = "50px", height = "50px", className, ...props }: Props): React$Node => {
  const {
    configuration: { logoSecondary },
  } = useContext(StoreInfoContext);

  return (
    <div {...props} className={cn(styles.block, className)}>
      {logoSecondary && logoSvg(logoSecondary, width, height) }
    </div>
  );
};

const Logo = ({ width = "50px", height = "50px", loginViewLogo, className, ...props }: Props): React$Node => {
  const {
    info: { name },
    configuration: { logo },
  } = useContext(StoreInfoContext);

  const logoType = loginViewLogo ? loginViewLogo : logo;

  return (
    <div {...props} className={cn(styles.block, className)}>
      {logo && logoSvg(logoType, width, height) }
      {!logo && name &&
        <span className={styles.name}>
          {name}
        </span>
      }
    </div>
  );
};

export default Logo;
